@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply bg-surface;
  }

  .content-area {
    height: calc(100vh - theme('spacing.14'));
  }

  .ringed-focus {
    @apply focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-80 focus:ring-offset-surface;
  }

  .focusable,
  .focusable-wrapper {
    @apply focus:outline-none focus:ring-0;
  }
  :focus > .focusable {
    @apply ring;
  }

  a {
    @apply ringed-focus focus:ring-offset-2;
  }

  .small-caps {
    font-variant: small-caps;
  }

  .tpg-app-title {
    @apply font-display font-medium text-secondary text-left text-xl;
  }
  .tpg-title-6 {
    @apply font-sans font-medium text-secondary text-left text-base;
  }
  .tpg-menu-1 {
    @apply font-sans font-semibold text-secondary text-left text-sm tracking-wider;
  }
  .tpg-menu-2 {
    @apply font-sans font-medium text-secondary text-left text-sm tracking-widest;
  }
  .tpg-list-1 {
    @apply font-sans font-bold text-secondary text-left text-xs;
  }
  .tpg-list-2 {
    @apply font-sans font-medium text-secondary text-left text-xs;
  }
  .tpg-caption {
    @apply font-sans font-medium text-secondary text-left text-tiny;
  }
  .tpg-prop-label {
    @apply font-sans font-semibold text-secondary text-left text-xs;
  }
  .tpg-tool-label {
    @apply font-sans font-semibold text-secondary text-left text-tiny text-opacity-70;
  }
  .tpg-colrow {
    @apply font-sans font-normal text-secondary text-left text-sm;
  }
}

@layer components {
  .btn-primary {
    @apply inline-block bg-primary text-secondary font-bold px-8 py-3 rounded-lg uppercase tracking-wide shadow;
    @apply hover:bg-surface hover:bg-opacity-20;
    @apply ringed-focus focus:ring-inset focus:ring-offset-0 focus:ring-secondary focus:ring-opacity-80;
    @apply active:bg-surface active:bg-opacity-50 active:shadow-none active:ring-0;
  }

  .btn-secondary {
    @apply inline-block bg-surface text-secondary font-medium px-3 py-2 rounded-lg;
    @apply hover:bg-primary hover:bg-opacity-20 hover:shadow-sm;
    @apply ringed-focus focus:ring-inset focus:ring-offset-0;
    @apply active:bg-primary active:bg-opacity-50 active:shadow-none active:ring-0;
  }

  .icon-btn-primary {
    @apply block bg-primary text-secondary rounded-full;
    @apply hover:bg-surface hover:bg-opacity-20;
    @apply ring-secondary ring-opacity-80 ring-inset;
    @apply active:bg-surface active:bg-opacity-50 active:ring-0;
  }

  .icon-btn-primary-disabled {
    @apply block bg-primary text-secondary rounded-full opacity-50 cursor-default;
    @apply ring-secondary ring-opacity-80 ring-inset;
  }

  .icon-btn-secondary {
    @apply block bg-surface text-secondary rounded-full;
    @apply hover:bg-primary hover:bg-opacity-20;
    @apply ring-primary ring-opacity-80 ring-inset;
    @apply active:bg-primary active:bg-opacity-50 active:ring-0;
  }

  .list-element {
    @apply flex-none;
  }

  .list-element .focusable {
    @apply tpg-list-2 px-4 h-full w-full flex items-center;
    @apply hover:bg-primary hover:bg-opacity-20;
    @apply ring-primary ring-opacity-80 ring-inset;
    @apply active:bg-primary active:bg-opacity-50 active:ring-0;
  }

  .no-spinner::-webkit-inner-spin-button,
  .no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .card-dimensions-grid {
    position: relative;
    display: grid;
    grid: 0 auto / 48px 16px 48px;
    grid-auto-flow: column;
  }
}

/* The popper-arrow styles are out of layer because PurgeCSS was messing with them */
.popper-arrow,
.popper-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.popper-arrow {
  visibility: hidden;
}

.popper-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.popper-container[data-popper-placement^='top'] > .popper-arrow {
  bottom: -4px;
}

.popper-container[data-popper-placement^='bottom'] > .popper-arrow {
  top: -4px;
}

.popper-container[data-popper-placement^='left'] > .popper-arrow {
  right: -4px;
}

.popper-container[data-popper-placement^='right'] > .popper-arrow {
  left: -4px;
}
